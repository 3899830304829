<template>
    <div class="kanban-board">
        <div
            v-for="(column, columnIndex) in columns"
            :key="column.id"
            class="kanban-column"
            @dragover.prevent
            @drop="onDrop(columnIndex)"
        >

            <h2>
                {{ column.name }}
                <span :class="'badge bg-' + column.color">{{ column.tasks.length }}</span>
            </h2>
            
            <div
                v-for="(task, taskIndex) in column.tasks"
                :key="task.id"
                class="kanban-card"
                draggable="true"
                @dragstart="onDragStart(columnIndex, taskIndex)"
                @click="open(task)"
            >
                {{ task.title }}
            </div>
        </div>
    </div>

    <!-- Картка перегляду репорту -->
    <viewReport 
        v-if="showViewReport == true"
        @close="showViewReport = false"
        :objCard="this.bug_report"
    />
</template>

<script>
import viewReport from "@/views/software-testing/view_testing/formLeft/reports/view/index.vue"

export default {
    data() {
        return {
            showViewReport: false,
            draggedTask: null, // Інформація про завдання, яке перетягується
            bug_report: {
                "id": "67", 
                "title": "Будемо його брати в роботу і думати таски",
                "seriousness_defect": "minor",
                "priority": 1,
                "short_description": "Додатковий коментар до баг-репорту який має допомогти вирішити проблему",
                "steps": [
                {
                    "text": "Відкрив сторінку авторизації"
                },
                {
                    "text": "Вказав номер 3806666"
                },
                {
                    "text": "Отримав помилку 'oshibka'"
                }
                ],
                "actual_result": "Отримав помилку oshibka",
                "expected_result": "Отримання помилки 'Користувача з таким номер не зареєстровано'",
                "attachments": [
                {
                    "filename": "video.mp4",
                    "type": "MP4 File",
                    "size": "4.00",
                    "date": "11.09.2024"
                }
                ],
                "environment": [
                {
                    "os": "windows",
                    "version_os": "11",
                    "app_testing": "appskyservice",
                    "ver_app": "SkyService 2.1"
                },
                {
                    "os": "ios",
                    "version_os": "17.0",
                    "app_testing": "appskyservice",
                    "ver_app": "SkyService 5.2"
                },
                {
                    "os": "windows",
                    "version_os": "11",
                    "app_testing": "browser",
                    "browser": "chrome",
                    "ver_app": "120.0"
                }
                ]
            }
        };
    },
    components: {
        viewReport
    },
    props: ['columns'],
    methods: {
        open(task) {
            if(task.testingId) {
                console.log(task)
                this.showViewReport = true
            } else {
                alert('openTask')
            }
        },
        // Початок перетягування
        onDragStart(columnIndex, taskIndex) {
            this.draggedTask = { columnIndex, taskIndex };
        },

        // Подія при скиданні завдання в нову колонку
        onDrop(newColumnIndex) {
            if (this.draggedTask !== null) {
                const { columnIndex, taskIndex } = this.draggedTask;

                // Витягуємо завдання зі старої колонки
                const task = this.columns[columnIndex].tasks.splice(taskIndex, 1)[0];

                // Додаємо завдання в нову колонку
                this.columns[newColumnIndex].tasks.push(task);

                // Скидаємо draggedTask
                this.draggedTask = null;
            }
        }
    }
};
</script>

<style scoped>
    .kanban-board {
        display: flex;
        gap: 20px;
    }

    .kanban-column {
        width: 200px;
        padding: 10px;
        /* background-color: #f4f4f4; */
        border-radius: 5px;
        /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
    }

    .kanban-column h2 {
        text-align: start;
        font-size: 18px;
        margin-bottom: 10px;
    }

    .kanban-card {
        padding: 10px;
        margin: 5px 0;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 3px;
        cursor: pointer;
    }

    .kanban-card:active {
        cursor: grabbing;
    }
</style>